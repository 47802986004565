@tailwind base;
@tailwind components;

@import "loader";
@import "bootstrap-datepicker";
@import "datepicker";

main#scrollAnchor {
  @apply pb-24;
}

.cs__banner a {
  @apply mr-1 text-white underline;
}

@tailwind utilities;
