/*
 * Custom modifications to default styling
 */

.datepicker td, .datepicker th {
  //font-size: 13px;
  //line-height: 20px;
  //font-size: 15px;
  //line-height: 24px;
  @apply text-sm;
}

.input-daterange .input-group-addon {
  padding: 6px 12px;
  //font-size: 14px;
  font-weight: normal;
  //line-height: 24px;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: nowrap;
  vertical-align: middle;

  font-size: 17px;
  line-height: 42px;
}

.rc-time-picker .rc-time-picker-input {
  @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md;
}

.rc-time-picker.error .rc-time-picker-input {
  @apply block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
}

.rc-time-picker__popup {
  .rc-time-picker-panel-select {
    @apply text-base;
    @apply leading-relaxed;
  }

  .rc-time-picker-panel-input {
    @apply text-base;
    @apply leading-relaxed;
    @apply text-center;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: .25rem;
}
